import React from "react";
import "./Footer.css";
import logo from "../../Image/Bhatt-Dental.png";
import Term from "../../Terms & Condtion/terms";

function Footer() {
  const openNewPage = (url) => {
    const newWindow = window.open(url, "_self", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div className="instagram">
        <div className="insta-heading">
          <h2>Instagram</h2>
        </div>
        <div className="insta-post">
          <div className="insta-feed"></div>
          <div className="insta-feed1"></div>
          <div className="insta-feed2"></div>
        </div>
        <div className="btn-follow">
          <a href="https://www.instagram.com/bhattdental/" target="_blank">
            <button className="follow">
              {" "}
              <i class="fab fa-instagram" />
              &nbsp;Follow Us
            </button>
          </a>
        </div>
        {/* <iframe
          src="https://snapwidget.com/embed/960324"
          class="snapwidget-widget"
          allowtransparency="true"
          frameborder="0"
          // scrolling="no"
          // style="border:none; overflow:hidden;  width:100%; "
        ></iframe> */}

        {/* <script src="https://snapwidget.com/js/snapwidget.js"></script> */}
      </div>
      <div className="main-foo" id="foooter-cont">
        <div className="footer-container">
          <div className="footer-cont1">
            <h2>Contact Us</h2>
            <p>
           Bhatt Dental,<br/>
           B-56 Gurudev Apartments, Behind Hanuman Mandir, Chembur Naka, Chembur East Mumbai 400071
            </p>
            <p>
              <i class="fas fa-phone-alt"></i>
              <span> +91 98922 27839</span>
            </p>

            <p>
              <i class="fas fa-envelope"></i>
              <span>dr.vishal.bhatt@gmail.com</span>
            </p>
          </div>
          <div className="footer-cont2">
            <h2>Sitemap</h2>
            <div>
              <a
                onClick={() => {
                  openNewPage("https://www.bhattdental.com/aboutus");
                }}
              >
                About
              </a>
            </div>

            <div>
              <a>Services</a>
            </div>

            <div>
              <a
                onClick={() => {
                  openNewPage("https://pages.razorpay.com/bhattDental");
                }}
              >
                Payment
              </a>
            </div>
          </div>
          <div className="footer-cont3">
            <h2>Social</h2>
            <p>
              <i class="fab fa-facebook"></i>
              <span>Facebook</span>
            </p>
            <p>
              <i class="fab fa-instagram"></i>
              <span
                onClick={() => {
                  openNewPage("https://www.instagram.com/bhattdental/");
                }}
              >
                Instagram
              </span>
            </p>
          </div>
          <div className="footer-cont3">
            <h2>Timing</h2>
            <table>
              <tr>
                <td>Monday</td>
                <td className="td">11am-7pm </td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td className="td">11am-7pm </td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td className="td">11am-7pm </td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td className="td">11am-7pm </td>
              </tr>
              <tr>
                <td>Friday</td>
                <td className="td">11am-7pm </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td className="td">11am-3pm </td>
              </tr>
            </table>
          </div>
        </div>
        <p className="copyright">
          {" "}
          &copy;Bhatt Dental | <a href="/term">Terms & Conditon</a> |
          <a href="https://www.darshankotian.tech" target="_blank">
            {" "}
            Developed by Darshan Kotian
          </a>
          {/* </Link> */}
        </p>
      </div>
    </>
  );
}

export default Footer;
